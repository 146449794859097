
.blogTwoHeading{
    margin-top: 20px;
    font-family: "Bold_Helvetica";
    font-size: 32px;
}
.italic-Helvetica{
    font-family: "Italic_Helvetica";
}
.blog2Img{
    height: 606px;
    width: 85%;
}
.blogPara{
    font-size: 20px;
}
.div-with-text{
    padding: 1rem 7rem;
}
.div-with-heading{
    padding: 1rem 7rem;
}
.orangeLine{
    border-left: 5px solid #F37529;
    padding-left: 2rem;
}
.blogImageWidth{
    width: -webkit-fill-available;
}
.coachingHeading{
    font-size: 24px;
}
.authorName{
    font-size: 16px;
    font-family: "Roman_Helvetica";
}
.line-height-30{
    line-height: 30px;
}
@media only screen and (max-width: 426px) {
    .blogTwoHeading{
        margin: 2px;
        font-family: "Bold_Helvetica";
        font-size: 20px;
    }
    .blog2Img{
        height: auto;
        width: auto;
    }
    .coachingHeading{
        font-size: 18px;
    }
    .blogPara{
        font-size: 15px;
    }
    .authorName{
        font-size: 14px;
    }
    .div-with-text{
        padding: 2rem 5px;
    }
    .div-with-heading{
        padding: 1rem 1rem;
    }
}