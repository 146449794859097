
@import '../../shared/colors.scss';

// .aboutImg{
//     background-image: url("../../assets/images/AboutUsComponent.png");
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     background-attachment: fixed;
//     height: 100%;
// }    
.img-change{
    text-align: -webkit-center;
    padding-top: 15rem;
}
.triangleBg{
    
        background: linear-gradient(
            to top right,
            #F2F2F2 0%,
            #F2F2F2 50%,                                                                 
            #fff 50%,
            #fff 100%
        );
        padding: 50px;
        text-align: center;
        color: black;
    
}
.text-div{
    background-color:white;
    width: 300px;
    display: flex;
    justify-content: center;
    height: 1024px;
    align-items: center;
    padding: 15px;
}
.imgText{
    padding-top: 30%;
    padding-bottom: 30%;
    font-size: 36px;
    font-family: "Roman_Helvetica";
}
.img-size{
    height: 78.69px;
    width: 79px;
}
.imgAbtUs{
    height: 310px;
    width: 595px;
}

.differHeading{
    font-family: "Roman_Helvetica";
    font-size: 36px;
}
@media only screen and (max-width: 426px) {
    .imgText{
        padding-top: 30%;
        padding-bottom: 30%;
        font-size: 20px;
        font-family: "Roman_Helvetica";
    }
    .img-size{
        height: 63.78px;
        width: 64.25px;
    }
    .text-div{
        background-color: white;
        width: max-content;
        display: flex;
        justify-content: center;
        height: 279.471px;
        align-items: center;
        padding: 15px;
    }
    .img-change{
        text-align: -webkit-center;
        padding-top: 5rem;     
    }
    .imgAbtUs{
        height: 252px;
        width: 397px;
    }
    .differHeading{
        font-size: 20px;
    }
}
.head-font{
    font-family: "Light_Helvetica";
    font-size: 48px;
    color: #7b7b7b;
}
.values-head{
    font-family: "Roman_Helvetica";
    font-size: 36px;
}
.head-Decor{
    font-family: "Roman_Helvetica";
    font-size: 24px;
}
.card-head{
    font-family: "Roman_Helvetica";
    font-size: 18px;
}

.card-desc{
    font-size: 16px;
}

.romanHelvetica-font{
    font-family: "Roman_Helvetica";
}
.aboutPara{
    font-family:"Roman_Helvetica";
    font-size: 18px;
}
@media only screen and (max-width : 426px){
    .aboutPara{
        font-family:"Roman_Helvetica";
        font-size: 14px;
    }
    .card-desc{
        font-size: 12px;
    }
    .card-head{
        font-family: "Roman_Helvetica";
        font-size: 14px;
    }
    .head-Decor{
        font-family: "Roman_Helvetica";
        font-size: 14px;
        line-height: 30px;
    }
    .values-head{
        font-family: "Roman_Helvetica";
        font-size: 18px;
    }
    .head-font{
        font-family: "Light_Helvetica";
        font-size: 24px;
        color: #7b7b7b;
    }
    
    .centerText{
        text-align: center;
    }
}
.imageDimensions{
    height: 100%;
    width: 100%;
}
.background-orange{
    background-color: $color-orange;
    padding: 5%;
}
.background-values{
    background-color: $icons-background;
}
.text-orange{
    color: $color-orange;
}