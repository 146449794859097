
.BlogHeading{
    font-family: "Roman_Helvetica";
    font-size: 32px;
}
.aiHeading{
    line-height: 2.2rem;
}
.italic_Helvetica{
    font-family: "Italic_Helvetica";
}
.advantages{
    font-weight: 900;
}
.italic_Para{
    font-size: 20px;
}
.roman_Helvetica{
    font-family: "Roman_Helvetica";
}
.upperDesc{
    font-size: 20px;
}
.blogImageWidth{
    width: -webkit-fill-available;
}
.orangeLine{
    border-left: 5px solid #F37529;
    padding-left: 2rem;
}

@media only screen and (max-width: 426px) {
    .italic_Para{
        font-size: 15px;
    }
    .upperDesc{
        font-size: 15px;
    }
    .BlogHeading{
        font-size: 24px;
    }
}
@media only screen and (min-width: 768px) {
    .aiImage{
        width: 698px;
        height: 461px;
    }
}