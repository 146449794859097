@import '../../index.scss';
@import "../../shared/colors.scss";

.main-div{
    padding: 2rem 7rem;
}
.heading{
   font-size: 20px;
   font-weight: 400px;
   line-height: 32px;
   font-style: italic;
}
.line-height-30{
    line-height: 30px;
}
.paddingClass{
    padding-left: 8rem;
}
.font-20{
    font-size: 15px;
}
.fw-400{
    font-weight: 400px;
}
.blogImageWidth{
    width: -webkit-fill-available;
}
.light_Helvetica{
    font-family: "Light_Helvetica";
}
.medium_Helvetica{
    font-family: "Medium_Helvetica";
}
.font-24{
    font-size: 24px;
}
.orangeLine{
    border-left: 5px solid #F37529;
    padding-left: 2rem;
}
.list-color{
    color: #444343;
}
.italic-font{
    font-size: 20px;
}
.quoteColor{
    color: #444343;
}
@media only screen and (max-width: 426px) {
    .main-div{
        padding: 2rem 5px;
    }

    .font-in-mob{
        font-size: 15px;
    }
    .paddingClass{
        padding-left: 0rem;
    }
    .italic-font{
        font-size: 15px;
    }
}