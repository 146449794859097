@import "../../shared/colors.scss";


.imageDimensions{
    height: 590px;
    // width: 624px;
    // padding-top: 5px;
}
.imageDimensionsRight{
    width: -webkit-fill-available;
    height: 300px;
    // padding-top: 5px;
}
.boldHelvetica-font{
    font-family: "Bold_Helvetica";
}
.btn-color{
    // background-color: $color-orange !important;
    border-radius: 20px !important;
    color: $color-white !important;
}
.paddingCards{
    padding-left: 2rem !important;
}
.buttonDecor{
    text-align: end;
    padding: 10px;
}
.resource-head{
    font-size: 24px;
    overflow: hidden;
    max-height: 40px;
    
}

.resource-desc{
    font-size: 20px;
}
.btn-txt{
    font-size: 20px;
}
.blogs-head{
    font-size: 36px;
    font-family: "Bold_Helvetica";
}
.resourcesBg{
    background-color: #F2F2F2;
}
.whiteBg{
    background-color: white;
}
@media only screen and (max-width: 426px) {
    .resource-desc{
        font-size: 14px;
    }
    .blogs-head{
        font-size: 20px;
        font-family: "Bold_Helvetica";
    }
    .btn-txt{
        font-size: 16px;
    }
    .resource-head{
        font-size: 16px;
    }
    .imageDimensions{
        height: 200px;
        // width: 349px;
    }
    .imageDimensionsRight{
        height: 200px;
        // width: 349px;
    }
    .paddingCards{
        padding-left: 15px !important;
    }
    .displayOption{
        display: none !important;
    }
}
@media only screen and (min-width: 427px) {
    .desktopNone{
        display: none;
    }
}
