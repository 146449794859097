@import '../../shared/colors.scss';

.jobs-background {
    background-color: $jobs-div-background;
    padding-bottom: 2%;
}

.button-color {
    background-color: $color-orange  !important;
    border-radius: 25px !important;
    color: $color-white;
    width: 130px;
}

.jobs-head {
    font-size: 36px;
    font-weight: bold;
    font-family: "Roman_Helvetica";
}

.jobs-img {
    
    height: 100%;
    width: 100%;
    padding-top: 1rem;
    border-radius: 10px;
}

.bg-grey {
    background-color: $icons-background;
}

.jobs-text {
    font-family: "Roman_Helvetica";
}

.txt-size {
    font-size: 20px;
    font-family: "Roman_Helvetica";
}

@media only screen and (max-width: 426px) {
    .jobs-head {
        font-size: 20px;
    }

    .txt-size {
        font-size: 16px;
        font-family: "Roman_Helvetica";
    }

    .jobs-img {
        
        height: 100%;
        border-radius: 0px;
    }
}