@import '../../shared/colors.scss';
@import '../../index.scss';

// .imgscrolling{
//     background-image: url("../../assets/images/HwdIt.png");
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     background-attachment: fixed;
//     height: 100%;
// }
.howWeDo{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.imageChange{
    text-align: -webkit-center;
    // padding: 15rem;
    padding-top: 15rem !important;
    // padding-bottom: 15rem !important;
}
.text-division{
    background-color:white;
    width: 300px;
    display: flex;
    justify-content: center;
    height: 1024px;
    align-items: center;
    // padding: 15px;
}
.imageText{
    padding-top: 30%;
    padding-bottom: 30%;
    font-size: 36px;
    font-family: "Roman_Helvetica";
}
.science-text{
    padding-top: 20%;
    padding-bottom: 20%;
    
}
.icon-size{
    height: 56px;
    min-width: min-content;
}
.cardDivHeight{
    height: 155px;
}
.boxWork{
    background: url("../../assets/images/WorkCardWhite.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}
.jump{
    transition: transform .2s;
}
.jump .boxWork:hover{
    background: url("../../assets/images/WorkCardOrange.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    transform: translate(0,15px);
}
.boxContext{
    background: url("../../assets/images/ContextCardWhite.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}
.jump .boxContext:hover{
    background: url("../../assets/images/ContextCardOrange.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    transform: translate(0,15px);
}
.boxProfile{
    background: url("../../assets/images/ProfileCardWhite.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}
.jump .boxProfile:hover{
    background: url("../../assets/images/ProfileCardOrange.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    transform: translate(0,15px);
}
.boxCost{
    background: url("../../assets/images/CostCardWhite.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}
.jump .boxCost:hover{
    background: url("../../assets/images/CostCardOrange.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    transform: translate(0,15px);
}
.boxVisionary{
    background: url("../../assets/images/VisionaryCardWhite.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}
.jump .boxVisionary:hover{
    background: url("../../assets/images/VisionaryCardOrange.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    transform: translate(0,15px);
}
.boxIntegrator{
    background: url("../../assets/images/IntegratorCardWhite.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}
.jump .boxIntegrator:hover{
    background: url("../../assets/images/IntegratorCardOrange.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    transform: translate(0,15px);
}
.boxPioneer{
    background: url("../../assets/images/PioneerCardWhite.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}
.jump .boxPioneer:hover{
    background: url("../../assets/images/PioneerCardOrange.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    transform: translate(0,15px);
}
.boxSpecialist{
    background: url("../../assets/images/SpecialistCardWhite.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}
.jump .boxSpecialist:hover{
    background: url("../../assets/images/SpecialistCardOrange.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    transform: translate(0,15px);
}

.science-heading{
    color: $color-white;
    
}
.science-heading-text{
    color: $color-orange;
    
}

.science-div-background{
background-color:$science-div-background;
padding: 1%;
}
.text-orange{
    color: $color-orange;
}
.background-color{
    background-color: $icons-background;
    padding: 2%;
}
.romanHelvetica-font {
    font-family: "Roman_Helvetica";
}
.head-size{
    font-family: "Light_Helvetica";
    font-size: 48px;
    color: #7b7b7b;
    padding-bottom: 2rem;
}
.spacing{
    padding: 3rem !important;
}
@media only screen and (max-width: 426px) {
    .head-size{
        font-size: 24px;
    }
    .icon-size{
        height: 32px;
        width: 32px;
    }
    .imageChange{
        text-align: -webkit-center;
        // padding: 15rem;
        padding-top: 5rem !important;
        // padding-bottom: 5rem !important;
    }
    .imageText{
        padding-top: 30%;
        padding-bottom: 30%;
        font-size: 20px;
        font-family: "Roman_Helvetica";
    }
    .spacing{
        padding: 1rem !important;
    }
    .cardDivHeight{
        height: 500px;
    }
    .jump{
        margin-bottom: 5px;
    }
    .jump .boxWork:hover{
        transform: scale(1.1);
    }
    .jump .boxContext:hover{
        transform: scale(1.1);
    }
    .jump .boxProfile:hover{
        transform: scale(1.1);
    }
    .jump .boxCost:hover{
        transform: scale(1.1);
    }
    .jump .boxVisionary:hover{
        transform: scale(1.1);
    }
    .jump .boxIntegrator:hover{
        transform: scale(1.1);
    }
    .jump .boxPioneer:hover{
        transform: scale(1.1);
    }
    .jump .boxSpecialist:hover{
        transform: scale(1.1);
    }
    
}
.desc-size{
    font-size: 18px;
}
.method-head{
    font-size: 24px;
}
.method-desc{
    font-size: 18px;
}
.icon-desc{
    font-size: 16px;
}
@media only screen and (max-width: 426px) {
    .desc-size{
        font-size: 14px;
    }
    .method-head{
        font-size: 20px;
    }
    .method-desc{
        font-size: 14px;
    }
    .text-division{
        background-color: white;
        width: max-content;
        display: flex;
        justify-content: center;
        height: 279.471px;
        align-items: center;
        padding: 15px;
    }
}
@media only screen and (max-width : 1426px) and (min-width : 768px){
    .boxContext{
        margin-bottom: 10px;
    }
    .boxWork{
        margin-bottom: 10px;
    }
    // .boxProfile{
    //     margin-top: 9px;
    // }
    // .boxCost{
    //     margin-top: 9px;
    // }
    // .boxVisionary{
    //     margin-bottom: 10px;
    // }
    // .boxIntegrator{
    //     margin-bottom: 10px;
    // }
    .cardDivHeight{
        height: 180px;
    }
    // .boxSpecialist{
    //     margin-top: 9px;
    // }
    // .boxPioneer{
    //     margin-top: 9px;
    // }
}
@media only screen and (max-width : 1430px) and (min-width : 769px){
    .aboutIcon{
        text-align: left;
        padding-left: 4rem;
    }
    .cost-benefit{
        padding-left: 1rem!important;
    }
}
.bg-grey{
    background-color: $icons-background;
}