@import '../../shared/colors.scss';

.carouselPara{
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 0rem;
    font-family: "Roman_Helvetica";
    font-size: 18px;
}
.carouselStyle{
    background-color: $icons-background;
    padding: 2rem;
}
.authorDetails{
    margin-top: 10px;
    font-weight: bold;
    font-family: "Roman_Helvetica";
    font-size: 13px;
}
.slick-dots {
    position: initial;
}
@media only screen and (max-width: 426px) {
    .carouselPara{
        padding: 2rem;
        font-family: "Roman_Helvetica";
        font-size: 12px;
    }
}
.carouselImg{
    margin: 20px;
}