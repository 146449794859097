.anchorClass {
    color: #7b7b7b !important; 
    text-decoration: none !important;
}

.slickTitle{
    font-size: 24px;
}
.slickBg{
    background-color: #FAFAFA;
}
.slick-list {
    text-align: -webkit-center;
}
@media only screen and (max-width: 426px) {
    .sliderPadding{
        padding-left: 0px;
    }
    .slickTitle{
        font-size: 18px;
    }

}