@import "../../shared/colors.scss";

.authorName{
    font-family: "Roman_Helvetica";
    font-size: 20px;
}
.roman_Helvetica{
    font-family: "Roman_Helvetica";
}
.italic_Helvetica{
    font-family: "Italic_Helvetica";
}
.BlogHeading{
    font-size: 32px;
}
.italic-Para{
    font-size: 20px;
}
.DescPara{
    font-size: 20px;
}
.orangeLine{
    border-left: 5px solid #F37529;
    padding-left: 2rem;
}
.BlogImage{
    width: -webkit-fill-available;
}
.grayColor{
    color: $blog-gray-color;
}
@media only screen and (max-width: 426px) {
    .BlogHeading{
        font-size: 24px;
    }
    .italic-Para{
        font-size: 15px;
    }
    .DescPara{
        font-size: 15px;
    }
    .authorName{
        font-size: 18px;
    }
}