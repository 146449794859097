@import '../../shared/colors.scss';
.nav-components{
    font-family: "Roman_Helvetica";
    font-size: 18px;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: $color-orange !important;
    
}
.nav-bg{
    background-color: #FFFFFF1A !important;
}
.navbar-toggler{
    border: none !important;
}

.iconSizeMobile{
    height: 32px;
    width: 32px;
}

@media only screen and (max-width: 426px) {
    .iconSizeMobile{
        height: 24px !important;
        width: 24px !important;
    }
}