// @import "../../shared/colors.scss";

.slick-next:before, .slick-prev:before {
    color: #fff;
    background-color: gray;
    font-size: 20px;
    border-radius: 50%;
}
.custom-slick{
    text-align: -webkit-center;
    
}
.partners-head{
    font-family: "Roman_Helvetica";
    font-size: 36px;
}
@media only screen and (max-width: 426px) {
    .partners-head{
        font-size: 20px;
    }
    .slick-next:before, .slick-prev:before {
        color: #fff;
        background-color: gray;
        font-size: 20px;
        border-radius: 50%;
    }
}
.partners-Size{
    height: 48px;
    width: max-content;
}