body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Roman_Helvetica";
  src: url("./shared/Fonts/neue-helvetica-cufonfonts/Helvetica Neue LT 55 Roman.ttf");
}
@font-face {
  font-family: "Bold_Helvetica";
  src : url("./shared/Fonts/neue-helvetica-cufonfonts/Helvetica Neue LT 75 Bold.ttf")
}
@font-face {
  font-family: "Italic_Helvetica";
  src: url("./shared/Fonts/neue-helvetica-cufonfonts/Helvetica Neue LT 56 Italic.ttf");
}
@font-face {
  font-family: "Light_Helvetica";
  src: url("./shared/Fonts/GothamLight.otf");
}
@font-face {
  font-family: "Medium_Helvetica";
  src: url("./shared/Fonts/neue-helvetica-cufonfonts/Helvetica Neue LT 66 Medium Italic.ttf");
}