@import "../../shared/colors.scss";
.footerText{
    color: #fff;
    background-color: gray;
    font-size: 12px;
    
    font-family: "Roman_Helvetica";
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    
    
}
.mar-24{
    margin-left: 24px;
}
.bgOrange{
    background-color: $color-orange;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    // padding-left: 2rem;
}
.bgGrey{
    background-color: #9E9E9E;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
}
.overLap{
    
    position: relative;
    left: -24px;
}
.footBg{
    background-color: #444343;
}
.place{
    color: $color-orange;
    font-family: "Italic_Helvetica";
    font-weight: bold;
    font-size: 16px;
    padding-top: 1rem;
    margin-left:1.85rem ;
}
.compoItems{
    font-family: "Roman_Helvetica";
    font-size: 18px;
    margin: 1rem;
}
.mail{
    
    font-family: "Roman_Helvetica";
    // font-weight: bold;
    font-size: 16px;
    padding-top: 1rem;
    margin-bottom: 10px;
    text-align: center;
    
}
.componentMail{
    color: $color-orange;
    font-size: 16px;
    // color: gray;
}
.cols-in-center{
    text-align: center;
    align-self: center;
}

.country{
    font-family: "Roman_Helvetica";
    font-size: 12px;
}
.components{
    font-size: 16px;
    color: gray;
}
.details{
    font-family: "Roman_Helvetica";
    font-size: 16px;
}
.comp-head{
    font-size: 16px;
}
.footer-logo{
    width: 113px;
    height: 64px;
}
.social-icons{
    height: 20px;
    width: 20px;
}
.div-width{
    width: max-content !important;
}
.padding2Left{
    padding-left: 2rem;
}
.padding2Right{
    padding-right: 3rem;
}
.positionClass{
    display: flex;
    flex-direction: row;
    // justify-content: flex-end;
    margin-left: 25px;
}
.flexClass{
    display: flex;
}
.ulMargin{
    margin-bottom: 0rem;
    padding-left: 2rem;
}
@media only screen and (max-width: 426px) {
    .footer-width{
    
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1 * var(--bs-gutter-y));
            // margin-right: calc(-.5 * var(--bs-gutter-x));
            // margin-left: calc(-.5 * var(--bs-gutter-x));

    }
    .alignmentClass{
        margin-left: 34px;
    }
    .mar-24{
        margin-left: 0px;
    }
    .overLap{
        left:0px
    }
    .footerText{
     
        font-size: 10px;
        
        font-family: "Roman_Helvetica";
        font-size: 10px;
        display: block;
    }
    .bgOrange{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .bgGrey{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    
    .flexClass{
        display: flex;
        flex-direction: column;
    }
    .compoItems{
        font-size: 16px;
    }
    .place{
        margin-left: 0rem;
    }
    
    .padding2Left{
        padding-left: 0px;
    }
    .contentPosition{
        text-align-last: center;
    }
    .padding2Right{
        padding-right: 0rem;
        padding-top: 1rem;
    }
    .positionClass{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 0rem;
    }
    .components{
        font-size: 12px;
        color: gray;
    }
    .comp-head{
        font-size: 12px;
    }
    .ulMargin{
        padding-left: 0rem;
    }
    .footer-logo{
        width: 78px;
        height: 40px;
    }
    .social-icons{
        height: 30px;
        width: 30px;
    }
}

.saa-logo-icons{
    width: 100px;
    height: 60px;
}

.contact-icon{
    height: 30px;
    width: 30px;
    margin-top: 5px;
}
.contact-description{
    padding-left: 25px;
    padding-right: 20px;
    text-align: left;
    align-self: center;
}

.row-footer{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    // margin-top: calc(-1 * var(--bs-gutter-y));
}
.pl-10{
    padding-left: 10px;
}
hr{
    margin-top: 0px !important;
    margin-bottom: 1rem ;
}