
@import '../../shared/colors.scss';


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

img {
	max-width: 100%;
}

.arrows{
	height: 25px;
    position: absolute;
    bottom: 0;
	padding-bottom: 8px;
}

.section-title {
	font-size: 28px;
	margin-bottom: 20px;
	padding-bottom: 20px;
	font-weight: 400;
	display: inline-block;
	position: relative;
}
.section-title:after,

.section-title:before {
	content: "";
	position: absolute;
	bottom: 0;
}
.section-title:after {
	height: 2px;
	background-color: rgba(252, 92, 15, 0.46);
	left: 25%;
	right: 25%;
}

.section-title:before {
	width: 15px;
	height: 15px;
	border: 3px solid #fff;
	background-color: #fc5c0f;
	left: 50%;
	transform: translatex(-50%);
	bottom: -6px;
	z-index: 9;
	border-radius: 50%;
}

/* CAROUSEL STARTS */
.customer-feedback .owl-item img {
	width: 85px;
	height: 85px;
}

.feedback-slider-item {
	position: relative;
	padding: 10px;
	margin-top: -40px;
}

.customer-name {
    color: $color-orange;
	// margin-top: 15px;
	// margin-bottom: 25px;
	font-size: 20px;
	font-weight: 500;
	font-family: "Roman_Helvetica";
}

.paddin{
	padding-top: 3rem;
	padding-bottom: 1rem;
}

/* GREY BACKGROUND COLOR OF THE ACTIVE SLIDER */
.feedback-slider-item:after {
	content: "";
	position: absolute;
	left: 20px;
	right: 20px;
	bottom: 0;
	top: 30px;
    background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(68, 67, 67, 0.2);
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
	z-index: -1;
}


.img-height{
    height: 200px;
}
.img-width{
    width: 200px;
}
.readOption{
	color: black;
	text-decoration: underline;
	font-size: 14px !important;
}
@media only screen and (min-width: 768px) {
    .img-position{
        // position: absolute;
        right: 0;
    }
   
    .customer-name{
        // margin-top: 150px;
        font-size: 20px;
        font-weight: 500;
		font-family: "Roman_Helvetica";
    }
	.img-height{
		height: 125px;
	}
	.img-width{
		width: 125px;
	}
  }

.leadership-text{
    font-size: 36px;
}
@media only screen and (max-width: 426px) {
	.leadership-text{
		font-size: 20px;
	}
	.description{
		font-family: "Roman_Helvetica";
		font-size: 13px;
	}
	
	.designation{
		font-family: "Roman_Helvetica";
		font-size: 11px;
	}
	.img-height{
		height: 72px;
	}
	.img-width{
		width: 72px;
	}
	.customer-name {
		color: $color-orange;
		// margin-top: 15px;
		// margin-bottom: 25px;
		font-size: 14px;
		font-weight: 500;
		font-family: "Roman_Helvetica";
	}
}
.description{
	font-family: "Roman_Helvetica";
	font-size: 14px;
}

.designation{
	font-family: "Roman_Helvetica";
}

.romanHelvetica-font{
	font-family: "Roman_Helvetica";
}
