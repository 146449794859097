.authorName{
    font-family: "Roman_Helvetica";
    font-size: 20px;
}
.Blog5Image{
    width: -webkit-fill-available;
}
.font-20{
    font-size: 20px;
}
.Blog5Heading{
    font-size: 32px;
}
.bold_Helvetica{
    font-family: "Bold_Helvetica";
}
.takeAway{
    font-size: 20px;
}
.orangeLine{
    border-left: 5px solid #F37529;
    padding-left: 2rem;
}
@media only screen and (max-width: 426px) {
    .authorName{
        font-family: "Roman_Helvetica";
        font-size: 18px;
    }
    .font-20{
        font-size: 15px;
    }
    .Blog5Heading{
        font-size: 24px;
    }
    .takeAway{
        font-size: 24px;
    }
}