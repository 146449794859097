@import "../../shared/colors.scss";

.blogImages{
    height: 300px;
    width: -webkit-fill-available;
}
.arrowColor{
    color: $color-orange;
}
.blogTitle{
    font-family: "Bold_Helvetica";
    font-size: 24px;
    margin-bottom: 2rem !important;
}
.bodyText{
    margin-top: 2rem;
    padding-top: 4rem !important;
    padding-right: 4rem !important;
    padding-left: 3rem !important;
    padding-bottom: 4rem !important;
   
}
.imageMargin{
    margin-top:3rem;
}
.blogText{
    font-family: "Roman_Helvetica";
    font-size: 18px;
}
.img-fluid1 {
    padding-top: 5rem;
    height: 85% !important;
}
.btnText{
    color: black;
    font-family: "Roman_Helvetica" !important;
    font-size: 16px;
    margin-right: 30px;
}
@media only screen and (max-width: 426px){
    .dontDisplay{
        display: none;
    }
    .blogTitle{
        font-family: "Bold_Helvetica";
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0px !important;
    }
    .reverse{
        display: flex;
        flex-flow: column-reverse;
    }
    .bodyText{
        padding: 1rem !important;
    }
    .btnText{
        color: black;
        font-family: "Roman_Helvetica" !important;
        font-size: 14px !important;
        margin-right: 0px;
    }
    .img-fluid1{
        height: 100% !important;
    }
    .imageMargin{
        margin-top:0rem;
    }
}
