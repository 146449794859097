@import '../../shared/colors.scss';
// @import '../../index.scss';

.paragraph1{
    font-family: 'Roman_Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: $paragraph_description;
}
.company-name{
    color: $color-orange;
    font-size: 18px;
}
.background-orange{
    background-color: $color-orange;
    padding: 5%;
}

.desktopView{
    width: 100%;
    // height: 100vh;
    display: block;
}
.mobileView{
    display: none;
}
.para-padding{
    padding-left: 1rem;
    padding-right: 1rem;
}
@media only screen and (max-width: 426px) {
    .paragraph1{
        font-family: 'Roman_Helvetica';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $paragraph_description;
    }
    .company-name{
        color: $color-orange;
        font-size: 14px;
    }
    .desktopView{
        display: none;
    }
    .mobileView{
        display: block;
        margin-top: 15px;
        width: 100%;
    }
    .para-padding{
        padding-left: 0px;
        padding-right: 0px;
    }
    
}